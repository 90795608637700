import React from "react";
import { Grid } from "@mui/material";
import Card from "../../Component/Card";
import PieChart from "../../Component/Pie";
import LineChart from "../../Component/LineChart";
import Bar from "../../Component/Bar";
import Navbar from "../../Component/Navbar/Navbar";

function Home() {
  return (
    <>
    
      <Grid container >
        
        <Grid item xs={12} md={8} sm={8}>
          <Card />
        </Grid>

        <Grid item xs={12} md={4} sm={4}>
          <PieChart />
        </Grid>

        <Grid item xs={12} md={8} sm={8} sx={{paddingRight:"1.5rem"}}>
          <Bar />
        </Grid>

        <Grid item xs={12} md={4} sm={4}>
          <LineChart />
        </Grid>

      </Grid>
      
    </>
  );
}

export default Home;
