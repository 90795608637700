import { TextField, Container, Button, Box } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Signup() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, setError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      setError("Please fill out all fields.");
    } else {
        const formData = {
            username: username,
            password: password
          };

          localStorage.setItem('formData', JSON.stringify(formData));
      navigate('/')
    }
  };

  return (
    // <Grid sx={{p:"10px" , border:"solid" , margin:"30px 450px"}}>

    <Container maxWidth="sm" sx={{ border: "solid", alignItems: "center",marginTop:"5rem" }}>
     
     <Box sx={{display:"flex",justifyContent:"center"}}>  <h1>SIGNUP</h1></Box>
     
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          type="email"
          margin="normal"
          name="userName"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          name="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px", marginBottom: "50px" }}
          // onClick={console.log(data)}
        >
          SIGNUP
        </Button>
<Box sx={{display:"flex",justifyContent:"center"}}>
    <Link to={'/'}> Already User! Login</Link>
</Box>

      </form>
      <p style={{ color: "red" }}>{err}</p>
    </Container>
    // </Grid>
  );
}

export default Signup;
