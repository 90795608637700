// import logo from './logo.svg';
import { Box } from '@mui/material';
// import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Component/Navbar/Navbar';
import Signup from './Signup';
import Login from './Login';
import Private from './Component/Private';



function App() {
 

  return (
    <Box sx={{margin:""}}>
       

     <Routes>

      <Route path='/' element={<Login/>} />
      <Route path='/signup' element={<Signup/>} />

      <Route element={<Private/>}>

      <Route path='/navbar' element={<Navbar/>} /> 
    
 
      </Route>
      </Routes>  
    </Box>
  );
}

export default App;


//create protected route

