import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Box, Paper, TableCell, Typography ,TableContainer, Table, TableHead, TableRow, TableBody} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CropFreeIcon from "@mui/icons-material/CropFree";

export default function Bar() {


  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Canada', 55.555, 201, '2.46%' , '0.26%'),
    createData('India', 24.152, 135, '0.58%' ,' 0.43%'),
    createData('UK', 15.640, 324, '5.15%', '2.47%'),
   
  ];



  return (
    <>
    <Paper
      // style={{ height: "400px" }}
      sx={{
        marginRight: { md: "1rem", sm: "0rem", xs: "-1rem" },
        marginTop: { xs: "1rem", sm: "-7rem", md: "-7rem" },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "50px",
            backgroundColor: "aqua",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Typography variant="body2" sx={{ marginLeft: "1.5rem" }}>
            COUNTRY STATS
          </Typography>

          <Box sx={{ display: "flex" }}>
            <Link>
              <ArrowDownwardIcon sx={{ marginRight: "1rem" }} />
            </Link>

            <Link>
              <CropFreeIcon sx={{ marginRight: "1rem" }} />
            </Link>
          </Box>
        </Box>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
          ]}
          series={[
            { data: [400, 200,350,200,400,100,250] },
            { data: [100, 200,400, 250,200,100,350] },
            
          ]}
          height={350}
        />
      </Box>


      <TableContainer component={Paper} sx={{ overflowX: 'auto', marginTop: '1rem' }}>
  <Table >
    <TableHead>
      <TableRow>
        <TableCell>Country</TableCell>
        <TableCell align="right">Session</TableCell>
        <TableCell align="right">Goals</TableCell>
        <TableCell align="right">Goals Rate</TableCell>
        <TableCell align="right">Bounce Rate</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <TableRow
          key={row.name}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.calories}</TableCell>
          <TableCell align="right">{row.fat}</TableCell>
          <TableCell align="right">{row.carbs}</TableCell>
          <TableCell align="right">{row.protein}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

      </Paper>



    </>
  );
}