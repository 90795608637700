import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";

export default function () {
  const cards = [
    {
      title: "Visitors",
      increase: "+10%",
      value: "13.7k",
      target: "172,458 Target User",
      gradientBackground:
        "linear-gradient(273deg, rgba(131,58,180,1) 0%, rgba(155,52,150,1) 0%, rgba(180,46,119,1) 0%, rgba(253,29,29,1) 85%, rgba(252,176,69,1) 100%)",
    },
    {
      title: "Bounce Rate",
      increase: "+17%",
      value: "41.6%",
      target: "472,458 Targeted Target User",
      gradientBackground:
        "linear-gradient(273deg, rgba(237,235,164,1) 0%, rgba(168,227,190,1) 0%, rgba(237,235,164,1) 47%, rgba(151,225,196,1) 92%, rgba(122,222,206,1) 100%, rgba(88,218,219,1) 100%)",
    },
    {
      title: "Users",
      increase: "+10%",
      value: "19M",
      target: "172458 Target User",
      gradientBackground:
        "linear-gradient(273deg, rgba(237,235,164,1) 79%, rgba(34,193,195,1) 100%)",
    },


    {
      title: "New Contacts",
      increase: "-5%",
      value: "75",
      target: "172,458 Target User",
      gradientBackground:
        "linear-gradient(319deg, rgba(10,105,231,1) 12%, rgba(4,101,116,1) 25%, rgba(144,255,251,1) 87%, rgba(9,121,117,1) 100%)",
    },
  ];

  return (
    <Stack gap={26} direction={{ xs: 'column', sm: 'row' }}
    spacing={{ xs: 1, sm: 2, md: 2 }} useFlexGap flexWrap="wrap"
    alignItems="center"
    sx={{display:'flex', flexWrap:'wrap'}}>
      {cards.map((card, index) => (
        <Card
          key={index}
          sx={{
            width: {xs:"80%",sm:"47%",md:{maxWidth:'350px'} }, maxHeight:'170px',
            // Adjust the width to fit two cards in a row with some spacing
            // marginBottom: "6rem", // Adjust spacing between rows
            background: card.gradientBackground,
          }}
        >
          
            <Box   sx={{display:"flex", justifyContent:"space-between", padding:'10px'}}>
              <Typography
                variant="body1"
                sx={{  color: "white" ,display:"flex",flexDirection:"row"}}
              >
                {card.title}
              </Typography>

             
              <Typography
                variant="body1"
                sx={{  color: "white" }}
              >
                {card.increase}
              </Typography>
           
            </Box>
            <Box  sx={{ padding:'0 10px'}}>
              <Typography sx={{ color: "white" }}>
                <h2>{card.value}</h2>
              </Typography>
              <Typography sx={{  color: "white" }}>
                <h5>{card.target}</h5>
              </Typography>
            </Box>
            
          
        </Card>
      ))}
    </Stack>
  );
}
