import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const uData = [0, 8, 2, 4, 0, 2, 8,1,5,6,4,10];

const pData = [0, 2, 1, 2, 0, 3, 8,2,4,6,8,10];
const xLabels = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  "11",
  '12'
];

export default function Linechart() {
  return (
    <Paper
    sx={{
      borderRadius: "10px",
      width: "100%",
      // height: "30rem",
      marginTop: { xs: "1rem", sm: "1rem", md: "1rem" },
      marginLeft: { sm: "1rem", md: "1rem" },
    }}
  >
     <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "50px",
            backgroundColor: "aqua",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Typography variant="body2" sx={{ marginLeft: "1.5rem" }}>
           YOUTUBE SUSCRIBER
          </Typography>

          <Box sx={{ display: "flex" }}>
            <Link>
              <PanoramaFishEyeIcon sx={{ marginRight: "1rem" }} />
            </Link>

            <Link>
              <MoreVertIcon sx={{ marginRight: "1rem" }} />
            </Link>
          </Box>
        </Box>

    <LineChart
      // width={500}
      
      height={300}
      series={[
        { data: uData, label: 'Mobile', area: true, stack: 'total', showMark: false },
        { data: pData, label: 'Desktop', area: true, stack: 'total', showMark: false },
       
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        '.MuiLineElement-root': {
          display: 'none',
        },
      }}
    />
    </Paper>
  );
}
