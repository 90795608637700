import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Paper, Typography, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";

const data = [
  { id: 0, value: 10, label: "series A" },
  { id: 1, value: 10, label: "series B" },
  { id: 2, value: 20, label: "series C" },
];

export default function Pie() {
  return (
    <Paper
      sx={{
        borderRadius: "10px",
        width: "100%",
        height: "30rem",
        marginTop: { xs: "1rem", sm: ".2rem", md: ".2rem" },
        marginLeft: { sm: "1rem", md: "1rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          backgroundColor: "aqua",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Typography variant="body2" sx={{ marginLeft: "1.5rem" }}>
          ANALYTICS AUDIENCE METRICS
        </Typography>
        <Link >
          <MoreHorizIcon sx={{ marginRight: "1rem" }} />
        </Link>
      </Box>

      <PieChart
        series={[
          {
            data,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, },
          },
        ]}
        height={250}
        tooltip={({ label, value }) => (
          <Tooltip title={`${label}: ${5}%`} arrow>
            <div />
          </Tooltip>
        )}
        sx={{marginTop:"10%"}}
      />
    </Paper>
  );
}
