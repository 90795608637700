import { TextField, Container, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, setError] = useState("");

  const navigate = useNavigate();



  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      setError("Please fill out all fields.");
    } else if (JSON.parse(localStorage.getItem("formData")) === null) {
      setError("User not found");
    } else if (
      username === JSON.parse(localStorage.getItem("formData")).username &&
      password === JSON.parse(localStorage.getItem("formData")).password
    ) {
      navigate("/navbar");
    } else {
      setError("Username or Password incorrect");
    }
  };

  return (
    // <Grid sx={{p:"10px" , border:"solid" , margin:"30px 450px"}}>

    <Container
      maxWidth="sm"
      sx={{ border: "solid", alignItems: "center", marginTop: "5rem" }}
    >
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          type="email"
          name="userName"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          name="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px", marginBottom: "50px" }}
          // onClick={console.log(data)}
        >
          Login
        </Button>
        <Link to={"/signup"}>New User SignUp</Link>
      </form>
      <p style={{ color: "red" }}>{err}</p>
    </Container>
    // </Grid>
  );
}

export default Login;
